import * as React from 'react';
import {MouseEvent, useEffect, useState} from 'react';
import {Editor} from '@tiptap/react';
import {ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps} from '@mui/material';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import FormatStrikethrough from '@mui/icons-material/FormatStrikethrough';
import {useTiptapEditor} from 'ra-input-rich-text';

export const FormatButtons = (props: ToggleButtonGroupProps) => {
    const editor = useTiptapEditor();
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        const handleUpdate = () => {
            setValues(() =>
                FormatValues.reduce((acc, value) => {
                    if (editor && editor.isActive(value)) {
                        acc.push(value);
                    }
                    return acc;
                }, [] as string[])
            );
        };

        if (editor) {
            editor.on('update', handleUpdate);
            editor.on('selectionUpdate', handleUpdate);
            editor.on('focus', handleUpdate);
        }

        return () => {
            if (editor) {
                editor.off('update', handleUpdate);
                editor.off('selectionUpdate', handleUpdate);
                editor.off('focus', handleUpdate);
            }
        };
    }, [editor]);

    const handleChange = (event: MouseEvent<HTMLElement>, newFormats: string[]) => {
        FormatValues.forEach((format) => {
            const shouldBeDeactivated = editor && editor.isActive(format) && !newFormats.includes(format);
            const shouldBeActivated = editor && !editor.isActive(format) && newFormats.includes(format);

            if (shouldBeDeactivated || shouldBeActivated) {
                FormatActions[format](editor);
            }
        });
    };

    return (
        <ToggleButtonGroup {...props} disabled={!editor?.isEditable} onChange={handleChange} value={values}>
            <ToggleButton value='bold'>
                <FormatBold fontSize='inherit' />
            </ToggleButton>
            <ToggleButton value='italic'>
                <FormatItalic fontSize='inherit' />
            </ToggleButton>
            <ToggleButton value='underline'>
                <FormatUnderlined fontSize='inherit' />
            </ToggleButton>
            <ToggleButton value='strike'>
                <FormatStrikethrough fontSize='inherit' />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

const FormatValues = ['bold', 'italic', 'underline', 'strike'];

const FormatActions = {
    bold: (editor: Editor) => editor.chain().focus().toggleBold().run(),
    italic: (editor: Editor) => editor.chain().focus().toggleItalic().run(),
    underline: (editor: Editor) => editor.chain().focus().toggleUnderline().run(),
    strike: (editor: Editor) => editor.chain().focus().toggleStrike().run()
};
