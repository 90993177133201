import jwt_decode, {JwtPayload} from 'jwt-decode';
import {httpClient} from 'providers';
import cookies from 'js-cookie';

const accessTokenName = 'mdd-access-token';
const refreshTokenName = 'mdd-refresh-token';
const domain = process.env.REACT_APP_NODE_ENV === 'production' ? '.mos.ru' : window.location.hostname;
export const refreshAuth = () => {
    const {accessToken, refreshToken} = getTokens();
    if (accessToken && refreshToken) {
        const decodedToken: JwtPayload = jwt_decode(accessToken || '');
        if (decodedToken.exp! < new Date().getTime() / 1000) {
            httpClient(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
                method: 'POST',
                body: JSON.stringify({refreshToken}),
                headers: new Headers({authorization: `JWT ${accessToken}`})
            })
                .then(({json}) => {
                    setTokens(json);
                })
                .catch((e) => Promise.reject(e));
        }
    }
    return Promise.resolve();
};

export const getTokens = () => ({
    accessToken: cookies.get(accessTokenName),
    refreshToken: cookies.get(refreshTokenName)
});
export const setTokens = (data: any) => {
    cookies.set(accessTokenName, data?.accessToken, {domain});
    cookies.set(refreshTokenName, data?.refreshToken, {domain});
};
export const removeTokens = () => {
    cookies.remove(accessTokenName, {domain});
    cookies.remove(refreshTokenName, {domain});
};
