import {Import, ListImagePreviewField} from 'components';
import {
    AutocompleteInput,
    BooleanInput,
    CreateButton,
    Datagrid,
    EmailField,
    ExportButton,
    FilterButton,
    FunctionField,
    Link,
    List,
    ReferenceInput,
    TextField,
    TextInput,
    TopToolbar,
    useCreatePath
} from 'react-admin';
import {getEnumArray, ROLE_ENUM} from 'utils';

const filters = [
    <TextInput label='Почта' source='email' />,
    <TextInput label='Имя' source='firstName' />,
    <TextInput label='Фамилия' source='lastName' />,
    <ReferenceInput
        label='Главное подразделение'
        source='mainDepartmentId'
        reference='department'
        sortBy='abbreviation'>
        <AutocompleteInput
            label='Главное подразделение'
            optionText='abbreviation'
            filterToQuery={(searchText: any) => ({abbreviation: searchText})}
        />
    </ReferenceInput>,
    <ReferenceInput label='Подразделение' source='departmentId' reference='department' sortBy='abbreviation'>
        <AutocompleteInput
            label='Подразделение'
            optionText='abbreviation'
            filterToQuery={(searchText: any) => ({abbreviation: searchText})}
        />
    </ReferenceInput>,
    <ReferenceInput label='Должность' source='jobPostId' reference='job-post' sortBy='name'>
        <AutocompleteInput
            label='Должность'
            optionText='name'
            filterToQuery={(searchText: any) => ({name: searchText})}
        />
    </ReferenceInput>,
    <AutocompleteInput label='Роль' source='role' choices={getEnumArray(ROLE_ENUM)} optionText='name' />,
    <BooleanInput label='Отправлен доступ на почту' source='isSendAccess' />,
    <BooleanInput label='Принял пользовательское соглашение' source='isUserAgreement' />,
    <BooleanInput label='Заблокирован' source='isBlocked' />
];

const ListActions = () => (
    <TopToolbar>
        <Import />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export default () => {
    const createPath = useCreatePath();
    return (
        <List actions={<ListActions />} filters={filters} sort={{field: 'id', order: 'desc'}} emptyWhileLoading>
            <Datagrid rowClick='edit'>
                <TextField label='№' source='id' />
                <ListImagePreviewField label='Изображение' source='avatar.url' sortable={false} />
                {/*<TextField label='Логин' source='login' />*/}
                <TextField label='Фамилия' source='lastName' />
                <TextField label='Имя' source='firstName' />
                <EmailField label='Почта' source='email' />
                <FunctionField
                    label='Главное подразделение'
                    source='mainDepartment.abbreviation'
                    sortBy='mainDepartmentId'
                    render={(record: any) => (
                        <Link
                            onClick={(e) => e.stopPropagation()}
                            to={createPath({resource: 'department', type: 'edit', id: record.mainDepartmentId})}>
                            {record.mainDepartment?.abbreviation}
                        </Link>
                    )}
                />
                <FunctionField
                    label='Подразделение'
                    source='department.abbreviation'
                    sortBy='departmentId'
                    render={(record: any) => (
                        <Link
                            onClick={(e) => e.stopPropagation()}
                            to={createPath({resource: 'department', type: 'edit', id: record.departmentId})}>
                            {record.department?.abbreviation}
                        </Link>
                    )}
                />
                <FunctionField
                    label='Должность'
                    source='jobPost.name'
                    sortBy='jobPostId'
                    render={(record: any) => (
                        <Link
                            onClick={(e) => e.stopPropagation()}
                            to={createPath({resource: 'job-post', type: 'edit', id: record.jobPostId})}>
                            {record.jobPost?.name}
                        </Link>
                    )}
                />
            </Datagrid>
        </List>
    );
};
