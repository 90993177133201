import {Box} from '@mui/material';
import {ListImagePreviewField} from 'components';
import {useEffect} from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    Datagrid,
    DateField,
    FunctionField,
    List,
    Pagination,
    ReferenceInput,
    TextField,
    TextInput,
    useRedirect
} from 'react-admin';
import {getEnumArray, getUserFullName, STORY_STATUS_ENUM} from 'utils';

const filters = [
    <TextInput label='№' source='ids' />,
    <ReferenceInput label='Пользователь' source='authorId' reference='user'>
        <AutocompleteInput
            sx={{width: 250}}
            label='Пользователь'
            optionText={(el) => getUserFullName(el)}
            filterToQuery={(searchText: any) => ({search: searchText})}
        />
    </ReferenceInput>,
    <TextInput label='Заголовок' source='title' />,
    <ReferenceInput label='Подразделение' source='departmentId' reference='department' sortBy='abbreviation'>
        <AutocompleteInput
            label='Подразделение'
            optionText='abbreviation'
            filterToQuery={(searchText: any) => ({abbreviation: searchText})}
        />
    </ReferenceInput>,
    <AutocompleteInput label='Статус' source='status' choices={getEnumArray(STORY_STATUS_ENUM)} optionText='name' />,
    <BooleanInput label='Закреплена' source='isPinned' />
];

export default () => {
    const redirect = useRedirect();

    useEffect(() => {
        const handleClick = (event: any) => {
            if (event.target.dataset.type === 'mention') {
                redirect('edit', 'user', event.target.dataset.id);
            }
        };

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <List
            filters={filters}
            sort={{field: 'id', order: 'desc'}}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            emptyWhileLoading>
            <Datagrid
                rowClick='edit'
                expand={({record}) => {
                    return (
                        <div>
                            <h4>Описание:</h4>
                            <Box
                                component={'div'}
                                sx={{
                                    mb: 5,
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    '& .mention': {
                                        borderRadius: '2px',
                                        backgroundColor: '#c6ddf4',
                                        cursor: 'pointer',
                                        color: '#2c2a29'
                                    }
                                }}
                                dangerouslySetInnerHTML={{__html: record?.description as string}}
                            />
                        </div>
                    );
                }}>
                <TextField label='№' source='id' />
                <ListImagePreviewField label='Изображение' source='file.url' sortable={false} />
                <FunctionField
                    label='Пользователь'
                    render={(record: any) => record.authors?.map((i: any) => getUserFullName(i.user)).join(', ')}
                />
                <TextField label='Заголовок' source='title' />
                <TextField label='Подразделение' source='department.abbreviation' sortBy='departmentId' />
                <DateField label='Дата создания' source='createdAt' showTime />
            </Datagrid>
        </List>
    );
};
