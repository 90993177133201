import React, {useState} from 'react';
import {
    Button,
    CreateBase,
    FileField,
    FileInput,
    Form,
    RadioButtonGroupInput,
    required,
    useDataProvider
} from 'react-admin';
import {Cancel as CancelIcon} from '@mui/icons-material';
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {useMutation} from 'react-query';
import {LoadingButton} from '@mui/lab';
import PublishIcon from '@mui/icons-material/Publish';

export default () => {
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);
    const {mutateAsync: mutateCreate, isLoading: loadingCreate} = useMutation((file: File) =>
        dataProvider.uploadFile('user/create_from_xlsx', file)
    );
    const {mutateAsync: mutateUpdate, isLoading: loadingUpdate} = useMutation((file: File) =>
        dataProvider.uploadFile('user/update_from_xlsx', file)
    );
    const {mutateAsync: mutateBlock, isLoading: loadingBlock} = useMutation((file: File) =>
        dataProvider.uploadFile('user/block_from_xlsx', file)
    );
    const loadImport = (data: any) => {
        const mutation = data.type === 'create' ? mutateCreate : data.type === 'update' ? mutateUpdate : mutateBlock;
        mutation(data.file).then((res) => {
            saveAs(
                res as Blob,
                `${FILE_NAME[data.type as keyof typeof FILE_NAME]} [${moment().format('DD.MM.YYYY')}].xlsx`
            );
        });
    };
    return (
        <>
            <Button label='Импорт' onClick={handleOpenModal}>
                <PublishIcon />
            </Button>

            <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleCloseModal}>
                <CreateBase redirect={false} mutationOptions={{onSuccess: handleCloseModal}}>
                    <Form onSubmit={loadImport}>
                        <DialogTitle>
                            <Typography>Импорт</Typography>
                        </DialogTitle>

                        <DialogContent>
                            <RadioButtonGroupInput
                                source='type'
                                validate={required()}
                                label='Выберите действие'
                                defaultValue={'create'}
                                choices={[
                                    {id: 'create', name: FILE_NAME['create']},
                                    {id: 'update', name: FILE_NAME['update']},
                                    {id: 'block', name: FILE_NAME['block']}
                                ]}
                            />
                            <FileInput source='file' label='Файл' validate={required()}>
                                <FileField source='src' title='title' />
                            </FileInput>
                        </DialogContent>
                        <DialogActions>
                            <Button label='ra.action.cancel' sx={{color: 'red'}} onClick={handleCloseModal}>
                                <CancelIcon />
                            </Button>
                            <LoadingButton
                                type={'submit'}
                                sx={{gap: 1}}
                                loading={loadingCreate || loadingUpdate || loadingBlock}
                                onClick={handleCloseModal}>
                                <PublishIcon /> Импорт
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                </CreateBase>
            </Dialog>
        </>
    );
};
const FILE_NAME = {
    create: 'Создание аккаунтов',
    update: 'Обновление аккаунтов',
    block: 'Блокировка аккаунтов'
};
