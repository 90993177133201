import {Box, CssBaseline, Typography} from '@mui/material';
import {AppBar, AppBarProps, ToggleThemeButton} from 'react-admin';
import {theme} from 'theme';

const darkTheme = {palette: {mode: 'dark' as const}};

const CustomAppBar = (props: AppBarProps) => {
    return (
        <AppBar {...props}>
            <CssBaseline enableColorScheme />
            <Typography variant='h6' color='inherit' id='react-admin-title' />
            <Box flex={1} />
            <ToggleThemeButton lightTheme={theme} darkTheme={darkTheme} />
        </AppBar>
    );
};

export default CustomAppBar;
