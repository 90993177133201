import {DateField, Edit, EditButton, FunctionField, Labeled, TabbedForm, TextInput} from 'react-admin';
import {getUserFullName} from 'utils';
import {ImagePreviewField} from 'components';

export default () => (
    <Edit>
        <TabbedForm>
            <TabbedForm.Tab label='Контент'>
                <TextInput label='№' source='id' fullWidth disabled />
                <ImagePreviewField source='author.imageLink' />
                <Labeled label='Пользователь'>
                    <FunctionField render={(record: any) => getUserFullName(record.author)} />
                </Labeled>
                <TextInput label='Текст' source='text' fullWidth />
                <Labeled label='Дата создания'>
                    <DateField source='createdAt' showTime />
                </Labeled>
                <Labeled label='Источник'>
                    <FunctionField
                        render={(record: any) => (
                            <EditButton
                                record={record.story}
                                resource='story'
                                label={`[История] ${record.story.title}`}
                            />
                        )}
                    />
                </Labeled>
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
);
