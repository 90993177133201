import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    Create,
    Edit,
    email,
    minLength,
    PasswordInput,
    ReferenceInput,
    required,
    TabbedForm,
    TextInput,
    TopToolbar,
    useDataProvider,
    useNotify
} from 'react-admin';
import {getEnumArray, ROLE_ENUM} from 'utils';
import {MediaImageInput} from 'components';
import {useMutation} from 'react-query';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const Form = ({isCreate}: {isCreate?: boolean}) => {
    const defaultPassword = useMemo(() => Math.random().toString(36).substring(2, 10), []);
    const minLengthPassword = minLength(8, 'Минимальное допустимое количество символов - 8');

    return (
        <>
            <MediaImageInput label='Изображение' source='avatar' />
            <PasswordInput
                label='Пароль'
                source='password'
                fullWidth
                validate={(isCreate && [required(), minLengthPassword]) || minLengthPassword}
                defaultValue={(isCreate && defaultPassword) || undefined}
            />
            <TextInput label='Фамилия' source='lastName' fullWidth validate={required()} />
            <TextInput label='Имя' source='firstName' fullWidth validate={required()} />
            <TextInput label='Почта' source='email' type='email' fullWidth validate={[required(), email()]} />
            <ReferenceInput source='mainDepartmentId' reference='department' sortBy='abbreviation'>
                <AutocompleteInput
                    fullWidth
                    optionText={(record) => record.abbreviation + ' – ' + record.fullName}
                    label='Главное подразделение'
                    filterToQuery={(searchText: string) => ({abbreviation: searchText})}
                />
            </ReferenceInput>
            <ReferenceInput source='departmentId' reference='department' sortBy='abbreviation'>
                <AutocompleteInput
                    fullWidth
                    label='Подразделение'
                    optionText={(record) => record.abbreviation + ' – ' + record.fullName}
                    filterToQuery={(searchText: string) => ({abbreviation: searchText})}
                />
            </ReferenceInput>
            <ReferenceInput source='jobPostId' reference='job-post' sortBy='name'>
                <AutocompleteInput
                    optionText='name'
                    label='Должность'
                    filterToQuery={(searchText: string) => ({name: searchText})}
                    fullWidth
                />
            </ReferenceInput>
            <AutocompleteInput
                label='Роль'
                source='role'
                validate={required()}
                choices={getEnumArray(ROLE_ENUM)}
                optionText='name'
                fullWidth
                defaultValue={1}
            />
            {/*<BooleanInput label='Отправлен доступ на почту' source='isSendAccess' fullWidth />*/}
        </>
    );
};
export const UserCreate = () => {
    return (
        <Create>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <Form isCreate />
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
};

export const UserEdit = () => {
    const dataProvider = useDataProvider();
    const {id} = useParams();
    const notify = useNotify();
    const resetPasswordMutation = useMutation((id: string) => dataProvider.resetPassword(id));

    const resetPassword = () => {
        resetPasswordMutation.mutateAsync(id!).then(() => notify('Новый пароль был отправлен на почту'));
    };

    return (
        <Edit
            actions={
                <TopToolbar>
                    <Button label='Сбросить пароль' onClick={resetPassword}>
                        <RestartAltIcon />
                    </Button>
                </TopToolbar>
            }>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <TextInput label='№' source='id' fullWidth disabled />
                    <Form />
                    <BooleanInput
                        label='Принял пользовательское соглашение'
                        source='isUserAgreement'
                        fullWidth
                        disabled
                    />
                    <BooleanInput label='Заблокирован' source='isBlocked' fullWidth />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};
