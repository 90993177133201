import {Stack} from '@mui/material';
import ButtonWithDate from './components/ButtonWithDate';
import Simple from './components/Simple';
import {useMutation, useQuery} from 'react-query';
import {useDataProvider} from 'react-admin';
import {UseMutationResult} from 'react-query/types/react/types';

export default () => {
    const dataProvider = useDataProvider();
    const {
        data: publishedStories,
        isLoading: isPublishedStoriesLoading,
        isError: isPublishedStoriesError
    } = useQuery('published-stories', () => dataProvider.getJSONStats('published-stories-count'));

    const storyAuthorReportMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('story-author-report', data)
    );
    const storyReportMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('story-report', data)
    );
    const storyDepartmentReportMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('story-department-report', data)
    );
    const storyDumpMutation: UseMutationResult = useMutation((data) => dataProvider.getFileStats('story-dump', data));

    return (
        <Stack spacing={3}>
            <h1>Статистика по историям</h1>
            <Simple
                label={'Количество опубликованных историй'}
                value={publishedStories}
                loading={isPublishedStoriesLoading || isPublishedStoriesError}
            />
            <ButtonWithDate label={'Количество опубликованных историй по авторам'} query={storyAuthorReportMutation} />
            <ButtonWithDate label={'Количество лайков по историям'} query={storyReportMutation} />
            <ButtonWithDate
                label={'Количество лайков историй по подразделениям авторов'}
                query={storyDepartmentReportMutation}
            />
            <ButtonWithDate label={'Выгрузка историй с текстом'} query={storyDumpMutation} />
        </Stack>
    );
};
