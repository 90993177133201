import React from 'react';
import {Labeled, PublicFieldProps, useRecordContext} from 'react-admin';
import notFound from 'assets/not_found.png';
import {styled} from '@mui/material';
import _ from 'lodash';

export const ListImagePreviewField = ({source}: {source: string} & PublicFieldProps) => {
    const record = useRecordContext();
    return (
        <StyledImage
            src={_.get(record, source) || notFound}
            onError={(e) => (e.currentTarget.src = notFound)}
            height={100}
            width={100}
        />
    );
};

export const ImagePreviewField = ({source}: {source: string}) => {
    const record = useRecordContext();
    return (
        <Labeled label='Изображение'>
            <StyledImage
                style={{marginBottom: 20}}
                src={_.get(record, source) || notFound}
                onError={(e) => (e.currentTarget.src = notFound)}
                height={200}
                width={200}
            />
        </Labeled>
    );
};

const StyledImage = styled('img')({
    display: 'block',
    backgroundPosition: 'center',
    objectFit: 'cover',
    pointerEvents: 'none'
});
