import {Create, Edit, required, TabbedForm, TextInput} from 'react-admin';

const form = (
    <TabbedForm>
        <TabbedForm.Tab label='Контент'>
            <TextInput label='Название' source='name' fullWidth validate={required()} />
        </TabbedForm.Tab>
    </TabbedForm>
);

export const JobPostCreate = () => {
    return <Create>{form}</Create>;
};

export const JobPostEdit = () => {
    return <Edit>{form}</Edit>;
};
