import {Create, FileInput, ImageField, required, TabbedForm, TextInput} from 'react-admin';

export default () => {
    return (
        <Create>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <TextInput
                        label='Заголовок'
                        validate={required()}
                        source='title'
                        placeholder='Использовать в качестве заголовка - название файла'
                        fullWidth
                    />
                    <TextInput label='Описание' source='description' fullWidth />
                    <FileInput source='file' label='Файл'>
                        <ImageField source='src' title='title' />
                    </FileInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
};
