import {
    addRefreshAuthToAuthProvider,
    addRefreshAuthToDataProvider,
    Admin,
    Layout,
    LoadingPage,
    Resource
} from 'react-admin';
import {AppBar} from 'components';
import {authProvider, dataProvider, i18nProvider} from 'providers';
import {refreshAuth} from 'utils';
import {theme} from 'theme';
import {
    Business as BusinessIcon,
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    Diversity3 as Diversity3Icon,
    HistoryEdu as HistoryEduIcon,
    PeopleOutlined as PeopleOutlinedIcon,
    PermMediaOutlined as PermMediaOutlinedIcon,
    QueryStats as QueryStatsIcon,
    ShareLocation as ShareLocationIcon,
    Tag as TagIcon,
    ThumbDownOutlined as ThumbDownOutlinedIcon,
    TrackChanges as TrackChangesIcon,
    WorkOutlineOutlined as WorkOutlineOutlinedIcon
} from '@mui/icons-material';
import {JobPostCreate, JobPostEdit, JobPostList} from 'pages/JobPost';
import {TagCreate, TagEdit, TagList} from 'pages/Tag';
import {DepartmentCreate, DepartmentEdit, DepartmentList} from 'pages/Department';
import {CommentEdit, CommentList} from 'pages/Comment';
import {MediaCreate, MediaEdit, MediaList} from 'pages/Media';
import {StoryCreate, StoryEdit, StoryList} from 'pages/Story';
import {UserCreate, UserEdit, UserList} from 'pages/User';
import Stats from 'pages/Stats';
import {RejectionReasonCreate, RejectionReasonEdit, RejectionReasonList} from 'pages/RejectionReason';
import {DistrictCreate, DistrictEdit, DistrictList} from 'pages/District';
import {DivisionCreate, DivisionEdit, DivisionList} from 'pages/Division';
import {TerritorialUnitCreate, TerritorialUnitEdit, TerritorialUnitList} from 'pages/TerritorialUnit';

const AVAILABLE_RESOURCES = {
    MODERATOR: ['story', 'media', 'comment', 'rejection-reason']
};
const App = () => {
    return (
        <Admin
            theme={theme}
            dataProvider={addRefreshAuthToDataProvider(dataProvider, refreshAuth)}
            authProvider={addRefreshAuthToAuthProvider(authProvider, refreshAuth)}
            i18nProvider={i18nProvider}
            loading={() => <LoadingPage className={'Ra-LoadingPage'} />}
            disableTelemetry
            requireAuth
            layout={(props) => <Layout {...props} appBar={AppBar} />}>
            {(role) => {
                return [
                    <Resource
                        name='user'
                        options={{label: 'Пользователи'}}
                        icon={PeopleOutlinedIcon}
                        list={UserList}
                        edit={UserEdit}
                        create={UserCreate}
                    />,
                    <Resource
                        name='job-post'
                        options={{label: 'Должности'}}
                        list={JobPostList}
                        create={JobPostCreate}
                        edit={JobPostEdit}
                        icon={WorkOutlineOutlinedIcon}
                    />,
                    <Resource
                        name='media'
                        options={{label: 'Медиафайлы'}}
                        list={MediaList}
                        create={MediaCreate}
                        edit={MediaEdit}
                        icon={PermMediaOutlinedIcon}
                    />,
                    <Resource
                        name='story'
                        options={{label: 'Истории'}}
                        list={StoryList}
                        create={StoryCreate}
                        edit={StoryEdit}
                        icon={HistoryEduIcon}
                    />,
                    <Resource
                        name='comment'
                        options={{label: 'Комментарии'}}
                        list={CommentList}
                        edit={CommentEdit}
                        icon={ChatBubbleOutlineIcon}
                    />,
                    <Resource
                        name='rejection-reason'
                        options={{label: 'Причины отклонения'}}
                        list={RejectionReasonList}
                        create={RejectionReasonCreate}
                        edit={RejectionReasonEdit}
                        icon={ThumbDownOutlinedIcon}
                    />,
                    <Resource
                        name='tag'
                        options={{label: 'Теги'}}
                        list={TagList}
                        create={TagCreate}
                        edit={TagEdit}
                        icon={TagIcon}
                    />,
                    <Resource
                        name='department'
                        options={{label: 'Подразделения'}}
                        list={DepartmentList}
                        create={DepartmentCreate}
                        edit={DepartmentEdit}
                        icon={BusinessIcon}
                    />,
                    <Resource
                        name='district'
                        options={{label: 'Округи'}}
                        list={DistrictList}
                        create={DistrictCreate}
                        edit={DistrictEdit}
                        icon={ShareLocationIcon}
                    />,
                    <Resource
                        name='division'
                        options={{label: 'Дивизионы'}}
                        list={DivisionList}
                        create={DivisionCreate}
                        edit={DivisionEdit}
                        icon={Diversity3Icon}
                    />,
                    <Resource
                        name='territorial-unit'
                        options={{label: 'ТО'}}
                        list={TerritorialUnitList}
                        create={TerritorialUnitCreate}
                        edit={TerritorialUnitEdit}
                        icon={TrackChangesIcon}
                    />,
                    <Resource name='Stats' options={{label: 'Статистика'}} list={Stats} icon={QueryStatsIcon} />
                ].filter(
                    (i) =>
                        role === 'ADMIN' ||
                        AVAILABLE_RESOURCES[role as keyof typeof AVAILABLE_RESOURCES]?.includes(i.props.name)
                );
            }}
        </Admin>
    );
};

export default App;
