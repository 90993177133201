import {Edit, required, TabbedForm, TextInput} from 'react-admin';
import {ImagePreviewField} from 'components';

export default () => {
    return (
        <Edit>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <TextInput label='№' source='id' fullWidth disabled />
                    <TextInput label='Заголовок' source='title' fullWidth validate={required()} />
                    <TextInput label='Описание' source='description' fullWidth />
                    <TextInput label='Название' source='file.name' fullWidth disabled />
                    <ImagePreviewField source='file.url' />
                    <TextInput label='Размер' source='file.size' fullWidth disabled />
                    <TextInput label='Расширение' source='file.extension' fullWidth disabled />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};
