import {Box, styled, SxProps, Theme} from '@mui/material';
import notFound from 'assets/not_found.png';

const MediaPreview = ({media, sx}: {media: any; sx?: SxProps<Theme>}) => {
    return (
        <StyledCard key={media.id} sx={sx}>
            <StyledImage
                src={media?.file?.url || notFound}
                width={'100%'}
                height={'100%'}
                alt={media.title}
                loading='lazy'
                onError={(e) => (e.currentTarget.src = notFound)}
            />
            {media.title && <StyledText>{media.title}</StyledText>}
        </StyledCard>
    );
};

export default MediaPreview;

const StyledCard = styled('div')({
    position: 'relative',
    width: '150px',
    height: '150px',
    cursor: 'pointer',
    borderRadius: 4,
    overflow: 'hidden',

    '&:hover': {
        outline: `3px solid #C0C0C0 !important`,
        transition: 'outline 0.1s ease-in-out'
    }
});

const StyledImage = styled('img')({
    objectFit: 'cover'
});

const StyledText = styled(Box)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient( to top, #000000 -100%, transparent )',
    padding: '5px 10px',
    color: 'white',
    fontSize: '9pt'
});
