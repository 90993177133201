import {Create, Edit, required, TabbedForm, TextInput} from 'react-admin';

const form = (
    <TabbedForm>
        <TabbedForm.Tab label='Контент'>
            <TextInput label='Название' source='text' fullWidth validate={required()} />
        </TabbedForm.Tab>
    </TabbedForm>
);

export const RejectionReasonCreate = () => {
    return <Create>{form}</Create>;
};

export const RejectionReasonEdit = () => {
    return <Edit>{form}</Edit>;
};
