import {Box, Stack} from '@mui/material';
import DatePicker from './DatePicker';
import {LoadingButton} from '@mui/lab';
import {Form} from 'react-admin';
import moment from 'moment';
import {UseMutationResult} from 'react-query/types/react/types';
import {saveAs} from 'file-saver';

export default ({label, query}: {label: string; query: UseMutationResult}) => {
    const {mutateAsync, isLoading} = query;
    const loadData = (data: any) => {
        mutateAsync({
            dateFrom: data.dateFrom && moment(data.dateFrom).format('YYYY-MM-DD'),
            dateTo: data.dateTo && moment(data.dateTo).format('YYYY-MM-DD')
        }).then((res) => {
            saveAs(res as Blob, `${label} ${moment().format('DD.MM.YYYY')}.xlsx`);
        });
    };

    return (
        <Form onSubmit={loadData} defaultValues={{dateFrom: moment().subtract(1, 'months'), dateTo: moment()}}>
            <Box sx={{border: '2px solid #2196f3', borderRadius: '5px', p: 2}}>
                <h4>{label}</h4>
                <Stack direction={'row'} spacing={2} mb={1} mt={2}>
                    <DatePicker label='Диапазон от' format='YYYY.MM.DD' source='dateFrom' />
                    <DatePicker label='Диапазон до' format='YYYY.MM.DD' source='dateTo' />
                </Stack>
                <LoadingButton type={'submit'} loading={isLoading}>
                    Выгрузить
                </LoadingButton>
            </Box>
        </Form>
    );
};
