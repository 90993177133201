export const getUserFullName = (record: any) => {
    if (record) {
        const fullName = [record.lastName, record.firstName, record.middleName].filter((i) => i).join(' ');
        return `[${record.id}] ${fullName}`;
    }
    return null;
};

export const getEnumArray = (enumObj: object | undefined, idName = 'id', optionLabel = 'name') => {
    return enumObj
        ? Object.entries(enumObj).map(([key, value]) => ({
              [idName]: key,
              [optionLabel]: value
          }))
        : [];
};

export * from './constants';
export * from './auth';
