import React, {useState} from 'react';
import {InputProps, Labeled, useInput} from 'react-admin';
import {Box, styled} from '@mui/material';
import notFound from 'assets/not_found.png';
import {SelectMediaModal} from '../modals';

const MediaImage = ({source}: {source: string} & InputProps) => {
    const {id, field, fieldState} = useInput({source});
    const {onChange, value} = field;
    const [openModal, setOpenModal] = useState(false);

    const handleSelect = (data: any) => {
        onChange(data.file);
    };

    return (
        <>
            <Labeled label={'Изображение'} sx={{marginBottom: 3}}>
                <Box
                    onClick={() => setOpenModal(true)}
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        borderRadius: '4px',
                        overflow: 'hidden'
                    }}>
                    <StyledImage src={value?.url || notFound} onError={(e) => (e.currentTarget.src = notFound)} />
                    <StyledChangeContainer>
                        <StyledText>Изменить</StyledText>
                    </StyledChangeContainer>
                </Box>
            </Labeled>
            <SelectMediaModal open={openModal} onClose={() => setOpenModal(false)} onSelect={handleSelect} />
        </>
    );
};

export default MediaImage;

const StyledImage = styled('img')({
    width: 'auto',
    height: 'auto',
    maxHeight: '300px',
    margin: 0,
    padding: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
});

const StyledChangeContainer = styled('div')({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    background: 'rgba( 0, 0, 0, 0.5 )',
    opacity: 0,
    transition: 'opacity 0.1s ease-out',
    '&:hover': {
        opacity: 1,
        transition: 'opacity 0.1s ease-out'
    },

    '&:active': {
        opacity: 0.8,
        transition: 'opacity 0.1s ease-out'
    }
});

const StyledText = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    fontSize: '20pt',
    alignItems: 'center',
    pointerEvents: 'none',
    userSelect: 'none'
});
