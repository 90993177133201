import {Datagrid, List, TextField, TextInput} from 'react-admin';

const filters = [<TextInput label='Название' source='name' />];

export default () => (
    <List filters={filters} sort={{field: 'id', order: 'desc'}} emptyWhileLoading>
        <Datagrid rowClick='edit'>
            <TextField label='№' source='id' />
            <TextField label='Название' source='name' />
        </Datagrid>
    </List>
);
