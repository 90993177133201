import {defaultTheme} from 'react-admin';

// @ts-ignore
export const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiCard: {
            defaultProps: {
                variant: 'outlined' as const
            }
        }
    }
};
