import {
    AutocompleteInput,
    Datagrid,
    DateField,
    FunctionField,
    Link,
    List,
    ReferenceInput,
    TextField,
    useCreatePath
} from 'react-admin';
import {getUserFullName} from 'utils';
import {ListImagePreviewField} from 'components';

const filters = [
    <ReferenceInput label='Источник' source='storyId' reference='story'>
        <AutocompleteInput
            label='Источник'
            optionText={(el) => el && `[${el?.id}] ${el?.title || ''}`}
            filterToQuery={(searchText: any) => ({title: searchText})}
        />
    </ReferenceInput>,
    <ReferenceInput label='Пользователь' source='authorId' reference='user'>
        <AutocompleteInput
            sx={{width: 250}}
            label='Пользователь'
            optionText={(el) => getUserFullName(el)}
            filterToQuery={(searchText: any) => ({search: searchText})}
        />
    </ReferenceInput>
];

export default () => {
    const createPath = useCreatePath();
    return (
        <List filters={filters} sort={{field: 'id', order: 'desc'}} emptyWhileLoading>
            <Datagrid rowClick='edit'>
                <TextField label='№' source='id' />
                <ListImagePreviewField label='Изображение' source='author.avatar.url' sortable={false} />
                <FunctionField label='Пользователь' render={(record: any) => getUserFullName(record.author)} />
                <TextField label='Текст' source='text' />
                <DateField label='Дата создания' source='createdAt' showTime />
                <FunctionField
                    label='Источник'
                    source='storyId'
                    render={(record: any) => (
                        <Link
                            onClick={(e) => e.stopPropagation()}
                            to={createPath({resource: 'story', type: 'edit', id: record.storyId})}>
                            {record.story && `[${record.story?.id}] ${record.story?.title || ''}`}
                        </Link>
                    )}
                />
            </Datagrid>
        </List>
    );
};
