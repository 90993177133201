import {AutocompleteInput, Create, Edit, ReferenceInput, required, TabbedForm, TextInput} from 'react-admin';

const form = (
    <TabbedForm>
        <TabbedForm.Tab label='Контент'>
            <TextInput label='Название' source='name' fullWidth validate={required()} />
            <ReferenceInput source='divisionId' reference='division' sortBy='name'>
                <AutocompleteInput
                    optionText='name'
                    label='Дивизион'
                    filterToQuery={(searchText: string) => ({name: searchText})}
                    fullWidth
                />
            </ReferenceInput>
        </TabbedForm.Tab>
    </TabbedForm>
);

export const TerritorialUnitCreate = () => {
    return <Create>{form}</Create>;
};

export const TerritorialUnitEdit = () => {
    return <Edit>{form}</Edit>;
};
