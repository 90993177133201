import {Stack} from '@mui/material';
import ButtonWithDate from './components/ButtonWithDate';
import Simple from './components/Simple';
import {useMutation, useQuery} from 'react-query';
import {useDataProvider} from 'react-admin';
import {UseMutationResult} from 'react-query/types/react/types';

export default () => {
    const dataProvider = useDataProvider();

    const usersActivityMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('users-activity-report', data)
    );
    const authorRatingReportMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('authors-rating-report', data)
    );
    const storyRatingMutation: UseMutationResult = useMutation((data) =>
        dataProvider.getFileStats('story-rating-report', data)
    );
    const mainVisitsMutation: UseMutationResult = useMutation((data) => dataProvider.getFileStats('main-visits-report', data));


    return (
        <Stack spacing={3}>
            <h1>Статистика по сайту</h1>
            <ButtonWithDate
                label={'Статистика активности пользователей'}
                query={usersActivityMutation}
            />
            <ButtonWithDate label={'Рейтинг авторов'} query={authorRatingReportMutation} />
            <ButtonWithDate
                label={'Рейтинг публикаций'}
                query={storyRatingMutation}
            />
            <ButtonWithDate label={'Статистика посещений'} query={mainVisitsMutation} />
        </Stack>
    );
};
