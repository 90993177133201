import {TranslationMessages} from 'ra-core';

const russianMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: 'Добавить фильтр',
            add: 'Добавить',
            back: 'Назад',
            bulk_actions: '1 выбран |||| %{smart_count} выбрано |||| %{smart_count} выбрано',
            cancel: 'Отмена',
            clear_array_input: 'Очистить',
            clear_input_value: 'Очистить',
            clone: 'Дублировать',
            confirm: 'Подтвердить',
            create: 'Создать',
            create_item: 'Создать %{item}',
            delete: 'Удалить',
            edit: 'Редактировать',
            export: 'Экспорт',
            list: 'Список',
            refresh: 'Обновить',
            remove_filter: 'Убрать фильтр',
            remove_all_filters: 'Убрать все фильтры',
            remove: 'Удалить',
            save: 'Сохранить',
            search: 'Поиск',
            select_all: 'Выбрать все',
            select_row: 'Выбрать строку',
            show: 'Просмотр',
            sort: 'Сортировка',
            undo: 'Отменить',
            unselect: 'Не выбрано',
            expand: 'Раскрыть',
            close: 'Закрыть',
            open_menu: 'Открыть меню',
            close_menu: 'Закрыть меню',
            update: 'Обновить',
            move_up: 'Сдвинуть вверх',
            move_down: 'Сдвинуть вниз',
            open: 'Открыть',
            toggle_theme: 'Сменить тему',
            select_columns: 'Колонки'
        },
        boolean: {
            true: 'Да',
            false: 'Нет',
            null: ''
        },
        page: {
            create: 'Создать %{name}',
            dashboard: 'Главная',
            edit: '%{name} %{recordRepresentation}',
            error: 'Что-то пошло не так',
            list: '%{name}',
            loading: 'Загрузка',
            not_found: 'Не найдено',
            show: '%{name} %{recordRepresentation}',
            empty: 'Нет %{name}.',
            invite: 'Вы хотите добавить еще одну?'
        },
        input: {
            file: {
                upload_several: 'Перетащите файлы сюда или нажмите для выбора.',
                upload_single: 'Перетащите файл сюда или нажмите для выбора.'
            },
            image: {
                upload_several: 'Перетащите изображения сюда или нажмите для выбора.',
                upload_single: 'Перетащите изображение сюда или нажмите для выбора.'
            },
            references: {
                all_missing: 'Связанных данных не найдено',
                many_missing: 'Некоторые из связанных данных не доступны',
                single_missing: 'Связанный объект не доступен'
            },
            password: {
                toggle_visible: 'Скрыть пароль',
                toggle_hidden: 'Показать пароль'
            }
        },
        message: {
            about: 'Справка',
            are_you_sure: 'Вы уверены?',
            auth_error: 'Возникла ошибка при проверке токена авторизации.',
            bulk_delete_content:
                'Вы уверены, что хотите удалить %{name}? |||| Вы уверены, что хотите удалить %{smart_count} объекта? |||| Вы уверены, что хотите удалить %{smart_count} объектов?',
            bulk_delete_title:
                'Удалить %{name} |||| Удалить %{smart_count} %{name} |||| Удалить %{smart_count} %{name}',
            bulk_update_content:
                'Вы уверены, что хотите обновить %{name}? |||| Вы уверены, что хотите обновить %{smart_count} объекта? |||| Вы уверены, что хотите обновить %{smart_count} объектов?',
            bulk_update_title:
                'Обновить %{name} |||| Обновить %{smart_count} %{name} |||| Обновить %{smart_count} %{name}',
            clear_array_input: 'Вы уверены, что хотите очистить весь список?',
            delete_content: 'Вы уверены что хотите удалить этот объект?',
            delete_title: 'Удалить %{name} #%{id}',
            details: 'Описание',
            error: 'В процессе запроса возникла ошибка, и он не может быть завершен',
            invalid_form: 'Форма заполнена неверно, проверьте, пожалуйста, ошибки',
            loading: 'Идет загрузка, пожалуйста, подождите...',
            no: 'Нет',
            not_found: 'Ошибка URL или вы прошли по неверной ссылке',
            yes: 'Да',
            unsaved_changes: 'Некоторые из ваших изменений не были сохранены. Вы уверены, что хотите продолжить?'
        },
        navigation: {
            no_results: 'Нет данных',
            no_more_results: 'Страница %{page} выходит за пределы нумерации, попробуйте предыдущую',
            page_out_of_boundaries: 'Страница %{page} вне границ',
            page_out_from_end: 'Номер страницы не может быть больше максимальной',
            page_out_from_begin: 'Номер страницы не может быть меньше 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
            partial_page_range_info: '%{offsetBegin}-%{offsetEnd} больше %{offsetEnd}',
            current_page: 'Страница %{page}',
            page: 'Перейти к странице %{page}',
            first: 'Перейти к началу',
            last: 'Перейти в конец',
            next: 'Следующая',
            previous: 'Предыдущая',
            page_rows_per_page: 'Строк на странице:',
            skip_nav: 'Перейти к содержанию'
        },
        sort: {
            sort_by: 'Сортировать по %{field} %{order}',
            ASC: 'возрастанию',
            DESC: 'убыванию'
        },
        auth: {
            auth_check_error: 'Пожалуйста, авторизуйтесь для продолжения работы',
            user_menu: 'Профиль',
            username: 'Имя пользователя',
            password: 'Пароль',
            sign_in: 'Войти',
            sign_in_error: 'Ошибка аутентификации, попробуйте снова',
            logout: 'Выйти'
        },
        notification: {
            updated: 'Элемент обновлен |||| %{smart_count} обновлено |||| %{smart_count} обновлено',
            created: 'Элемент создан',
            deleted: 'Элемент удален |||| %{smart_count} удалено |||| %{smart_count} удалено',
            bad_item: 'Элемент не валиден',
            item_doesnt_exist: 'Элемент не существует',
            http_error: 'Ошибка сервера',
            data_provider_error: 'Ошибка dataProvider, проверьте консоль',
            i18n_error: 'Не удалось загрузить перевод для указанного языка',
            canceled: 'Операция отменена',
            logged_out: 'Ваша сессия завершена, попробуйте переподключиться/войти снова',
            not_authorized: 'Нет доступа.'
        },
        validation: {
            required: 'Обязательно для заполнения',
            minLength: 'Минимальное кол-во символов %{min}',
            maxLength: 'Максимальное кол-во символов %{max}',
            minValue: 'Минимальное значение %{min}',
            maxValue: 'Значение может быть %{max} или меньше',
            number: 'Должно быть цифрой',
            email: 'Некорректный email',
            oneOf: 'Должно быть одним из: %{options}',
            regex: 'Должно быть в формате (regexp): %{pattern}'
        },
        saved_queries: {
            label: 'Сохраненные запросы',
            query_name: 'Название запроса',
            new_label: 'Сохранить текущий запрос...',
            new_dialog_title: 'Сохранить запрос как',
            remove_label: 'Удалить сохраненный запрос',
            remove_label_with_name: 'Удалить запрос "%{name}"',
            remove_dialog_title: 'Удалить сохраненный запрос?',
            remove_message: 'Вы уверены, что хотите удалить запрос из списка сохраненных?',
            help: 'Отфильтруйте список и сохраните запрос на будущее'
        },
        configurable: {
            customize: 'Customize',
            configureMode: 'Configure this page',
            inspector: {
                title: 'Inspector',
                content: 'Hover the application UI elements to configure them',
                reset: 'Сбросить настройки',
                hideAll: 'Спрятать все',
                showAll: 'Показать все'
            },
            Datagrid: {
                title: 'Datagrid',
                unlabeled: 'Unlabeled column #%{column}'
            },
            SimpleForm: {
                title: 'Form',
                unlabeled: 'Unlabeled input #%{input}'
            },
            SimpleList: {
                title: 'Список',
                primaryText: 'Primary text',
                secondaryText: 'Secondary text',
                tertiaryText: 'Tertiary text'
            }
        }
    }
};

export default russianMessages;
