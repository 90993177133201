import React, {useState} from 'react';

import {Button, CreateBase, FileInput, Form, ImageField, SaveButton, TextInput, useRefresh} from 'react-admin';
import {Cancel as CancelIcon, Download as DownloadIcon} from '@mui/icons-material';
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

export default () => {
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => {
        refresh();
        setOpen(false);
    };

    return (
        <>
            <Button label='Быстрая загрузка' onClick={handleOpenModal}>
                <DownloadIcon />
            </Button>

            <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleCloseModal}>
                <CreateBase redirect={false} mutationOptions={{onSuccess: handleCloseModal}}>
                    <Form>
                        <DialogTitle>
                            <Typography>Добавить медиа</Typography>
                        </DialogTitle>

                        <DialogContent>
                            <TextInput
                                label='Заголовок'
                                source='title'
                                placeholder='Использовать в качестве заголовка - название файла'
                                fullWidth
                            />
                            <TextInput label='Описание' source='description' fullWidth />
                            <FileInput source='file' label='Файл'>
                                <ImageField source='src' title='title' />
                            </FileInput>
                        </DialogContent>
                        <DialogActions>
                            <Button label='ra.action.cancel' sx={{color: 'red'}} onClick={handleCloseModal}>
                                <CancelIcon />
                            </Button>
                            <SaveButton />
                        </DialogActions>
                    </Form>
                </CreateBase>
            </Dialog>
        </>
    );
};
