import React, {useEffect, useState} from 'react';
import {RichTextInput as RaRichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Mention from '@tiptap/extension-mention';
import {mentionSuggestionOptions} from './suggestion';
import {CharacterCount} from '@tiptap/extension-character-count';
import {FormatButtons} from './FormatButtons';
import {Editor} from '@tiptap/core';
import {Box} from '@mui/material';

const Chars = (props: any) => {
    const [chars, setChars] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setChars(props.editorRef.current?.storage?.characterCount.characters() || 0);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box color={'grey'} sx={{position: 'absolute', fontSize: 12, bottom: 5, right: 0}}>
            {chars + ' / ' + 2000}
        </Box>
    );
};

export const RichTextInput = ({source, label}: {source: string; label: string}) => {
    const editorRef = React.useRef<Editor | null>(null);

    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            <RaRichTextInput
                fullWidth
                sx={{
                    '& .tiptap': {height: 200, overflowY: 'scroll'},
                    '& .mention': {
                        borderRadius: '2px',
                        backgroundColor: '#c6ddf4'
                    }
                }}
                editorOptions={{
                    onCreate: (props: {editor: Editor}) => {
                        editorRef.current = props.editor;
                    },
                    extensions: [
                        Document,
                        Paragraph,
                        Text,
                        Bold,
                        Italic,
                        Strike,
                        Underline,
                        Mention.configure({
                            HTMLAttributes: {
                                class: 'mention'
                            },
                            suggestion: mentionSuggestionOptions
                        }),
                        CharacterCount.configure({
                            limit: 2000
                        })
                    ]
                }}
                toolbar={
                    <RichTextInputToolbar>
                        <FormatButtons />
                    </RichTextInputToolbar>
                }
                label={label}
                source={source}
            />
            <Chars editorRef={editorRef} />
        </Box>
    );
};
