import {Stack} from '@mui/material';
import Simple from './components/Simple';
import DatePicker from './components/DatePicker';
import {Form, useDataProvider} from 'react-admin';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useWatch} from 'react-hook-form';

const FormComponent = () => {
    const dataProvider = useDataProvider();
    const {date} = useWatch();

    const {
        data: visits,
        isLoading: isVisitsLoading,
        isError: isVisitsError
    } = useQuery(['visits', date], () =>
        dataProvider.getJSONStats('visits-count', {
            month: date.format('YYYY-MM')
        })
    );
    const {
        data: uniqueVisits,
        isLoading: isUniqueVisitsLoading,
        isError: isUniqueVisitsError
    } = useQuery(['unique-visits', date], () =>
        dataProvider.getJSONStats('unique-visits-count', {
            month: date.format('YYYY-MM')
        })
    );

    return (
        <Stack spacing={3}>
            <h1>Статистика посещений</h1>
            <DatePicker label='Посещения в месяце' views={['year', 'month']} format='YYYY.MM' source={'date'} />
            <Simple label='Количество посещений' value={visits} loading={isVisitsLoading || isVisitsError} />
            <Simple
                label='Количество уникальных посещений'
                value={uniqueVisits}
                loading={isUniqueVisitsLoading || isUniqueVisitsError}
            />
        </Stack>
    );
};

export default () => {
    return (
        <Form defaultValues={{date: moment()}}>
            <FormComponent />
        </Form>
    );
};
