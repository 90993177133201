import {useWatch} from 'react-hook-form';
import {AutocompleteInput, BooleanInput, DateInput, minValue, ReferenceInput} from 'react-admin';
import {getEnumArray, STORY_STATUS_ENUM} from 'utils';
import moment from 'moment';

export const StatusComponent = () => {
    const watchStatus = useWatch({name: 'status'});
    return (
        <>
            <AutocompleteInput
                label='Статус'
                source='status'
                choices={getEnumArray(STORY_STATUS_ENUM)}
                fullWidth
                optionText='name'
                defaultValue={'UNDER_CONSIDERATION'}
            />
            {watchStatus === 'MODERATION_FAILED' && (
                <ReferenceInput source='rejectionReasonId' reference='rejection-reason'>
                    <AutocompleteInput
                        label='Причина отклонения'
                        optionText='text'
                        fullWidth
                        filterToQuery={(searchText: any) => ({text: searchText})}
                    />
                </ReferenceInput>
            )}
        </>
    );
};

export const PinComponent = () => {
    const watchIsPinned = useWatch({name: 'isPinned'});
    return (
        <>
            <BooleanInput label='Закрепить' source='isPinned' />
            {watchIsPinned && (
                <DateInput
                    label='Закрепить до'
                    source='pinnedTo'
                    fullWidth
                    validate={minValue(moment().format('YYYY-MM-DD'), 'Вы не можете указать прошедшую дату')}
                />
            )}
        </>
    );
};
