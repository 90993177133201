import React from 'react';
import {maxLength, required, TextInput, TextInputProps} from 'react-admin';
import {Box} from '@mui/material';
import {useInput} from 'ra-core';

export const CounterInput = (props: TextInputProps & {maxLength: number}) => {
    const {label, source, validate, maxLength: max, ...rest} = props;
    const {field} = useInput({
        source
    });
    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            <TextInput
                label={label}
                source={source}
                {...rest}
                validate={[required(), maxLength(150, `Максимальное допустимое количество символов - ${150}.`)]}
            />
            <Box color={'grey'} sx={{position: 'absolute', bottom: 10, right: 0, fontSize: 12}}>
                {field?.value?.length + ' / ' + max}
            </Box>
        </Box>
    );
};
