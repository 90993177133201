import {Link, List, TextInput, useCreatePath, useListContext} from 'react-admin';
import {Box} from '@mui/material';
import {MediaPreview} from 'components';

const filters = [<TextInput label='Заголовок' source='title' />];

const GridList = () => {
    const {data} = useListContext();
    const createPath = useCreatePath();

    return (
        <Box sx={{margin: 3, gap: 2, display: 'flex', flexWrap: 'wrap'}}>
            {data?.map((item, index) => (
                <Link key={index} to={createPath({resource: 'media', id: item.id, type: 'edit'})}>
                    <MediaPreview media={item} />
                </Link>
            ))}
        </Box>
    );
};

export default () => {
    return (
        <List filters={filters} sort={{field: 'id', order: 'desc'}} perPage={50} emptyWhileLoading>
            <GridList />
        </List>
    );
};
