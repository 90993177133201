import {CircularProgress, Stack} from '@mui/material';

export default ({label, value, loading}: {label: string; value: number; loading: boolean}) => {
    return (
        <Stack spacing={1} sx={{border: '2px solid #2196f3', borderRadius: '5px', p: 2}}>
            <h4>{label}</h4>
            {loading ? <CircularProgress size={30} /> : <h2>{value}</h2>}
        </Stack>
    );
};
