import User from './User';
import Story from './Story';
import Site from './Site';
import {Stack} from '@mui/material';

export default () => {
    return (
        <Stack spacing={4} my={3}>
            <User />
            <Story />
            <Site />
        </Stack>
    );
};
