import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker, DatePickerProps, LocalizationProvider} from '@mui/x-date-pickers';
import 'moment/locale/ru';
import {useInput} from 'react-admin';

export default (props: DatePickerProps<any> & {source: string}) => {
    const {source} = props;
    const {id, field, fieldState} = useInput({source});
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='ru'>
            <DatePicker
                {...field}
                sx={{maxWidth: 250, mt: 0, '.MuiInputBase-root': {bgcolor: 'transparent'}}}
                {...props}
            />
        </LocalizationProvider>
    );
};
