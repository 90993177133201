export const STORY_STATUS_ENUM = {
    // DRAFT: 'Черновик',
    PUBLISHED: 'Опубликовано',
    MODERATION_FAILED: 'Не прошла модерацию',
    UNDER_CONSIDERATION: 'На модерации'
};

export const ROLE_ENUM = {
    USER: 'Пользователь',
    ADMIN: 'Администратор',
    MODERATOR: 'Модератор'
};
