import jwt_decode from 'jwt-decode';
import {AuthProvider, fetchUtils} from 'react-admin';
import {getTokens, removeTokens, setTokens} from 'utils';

const apiUrl = `${process.env.REACT_APP_API_URL}/auth`;
const httpClient = fetchUtils.fetchJson;

export const authProvider: AuthProvider = {
    login: (params: any) => {
        const {username, password} = params;
        return httpClient(`${apiUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'})
        })
            .then(({json}) => {
                setTokens(json);
                return Promise.resolve();
            })
            .catch((err) => {
                if (err?.message === 'INVALID_CREDENTIALS') {
                    return Promise.reject('Неверная почта или пароль');
                } else {
                    return Promise.reject(err?.message);
                }
            });
    },
    logout: (params: any) => {
        removeTokens();
        return Promise.resolve();
    },
    checkAuth: (params: any) => {
        const {accessToken} = getTokens();
        if (accessToken) {
            const decodedToken = jwt_decode(accessToken) as any;
            const role = decodedToken?.role;
            if (role !== 'ADMIN' && role !== 'MODERATOR') {
                return Promise.reject('Нет доступа');
            } else {
                return Promise.resolve();
            }
        }
        return Promise.reject();
    },
    checkError: ({status}: any) => {
        if (status === 401 || status === 403) {
            removeTokens();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        const {accessToken} = getTokens();
        return httpClient(`${apiUrl}/me`, {
            method: 'GET',
            headers: new Headers({authorization: `JWT ${accessToken}`})
        })
            .then(({json}) => {
                return Promise.resolve({
                    id: json.id,
                    fullName: `${json.lastName || ''} ${json.firstName || ''}`
                });
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    getPermissions: (params: any) => {
        const {accessToken} = getTokens();
        if (accessToken) {
            const decodedToken = jwt_decode(accessToken || '') as any;
            const role = decodedToken?.role;
            const isValidRole = ['ADMIN', 'MODERATOR'].includes(role!);
            return isValidRole ? Promise.resolve(role) : Promise.reject();
        }
        return Promise.resolve();
    }
};
