import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Theme, Typography} from '@mui/material';
import {Button, Filter, ListBase, Pagination, SearchInput, useListContext} from 'react-admin';
import {useState} from 'react';
import {Cancel as CancelIcon} from '@mui/icons-material';
import MediaPreview from '../MediaPreview';
import AddMedia from './AddMedia';

type SelectMediaProps = {
    open: boolean;
    onClose: () => void;
    onSelect: (el: any) => void;
};

const Content = ({open, onClose: handleClose, onSelect: handleSelect}: SelectMediaProps) => {
    const {data} = useListContext();
    const [selected, setSelected] = useState<any>(null);

    return (
        <Dialog fullWidth maxWidth='xl' open={open} onClose={handleClose}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '.RaFilter-form': {flex: '0 1 auto'}
                }}>
                <Typography>Изображение</Typography>
                <Filter>
                    <SearchInput source='title' alwaysOn />
                </Filter>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        paddingTop: 1,
                        display: 'flex',
                        gap: '20px',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                    {data?.map((el, index) => (
                        <Box key={index} onClick={() => setSelected(el)}>
                            <MediaPreview
                                media={el}
                                sx={{
                                    ...(el.id === selected?.id && {
                                        outline: (theme: Theme) => `3px solid ${theme.palette.primary.main} !important`,
                                        transition: 'outline 0.1s ease-in-out',
                                        '&:hover': {
                                            outline: (theme: Theme) =>
                                                `3px solid ${theme.palette.primary.main} !important`
                                        }
                                    })
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </DialogContent>
            {selected && (
                <DialogActions>
                    <Box sx={{width: '100%', paddingX: 3, paddingTop: 1, color: 'primary.main'}}>
                        <Typography fontWeight={'bold'}>
                            [{selected?.id}] {selected?.title}
                        </Typography>
                    </Box>
                </DialogActions>
            )}
            <DialogActions sx={{justifyContent: 'space-between', px: 3}}>
                <Button
                    label='Выбрать'
                    onClick={() => {
                        handleClose();
                        handleSelect(selected);
                    }}
                />
                <Pagination rowsPerPageOptions={[25, 50, 100]} />
                <Stack direction={'row'} alignItems={'center'}>
                    <AddMedia />

                    <Button label='ra.action.cancel' sx={{color: 'red'}} onClick={handleClose}>
                        <CancelIcon />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default (props: SelectMediaProps) => {
    return (
        <ListBase
            resource={'media'}
            perPage={50}
            sort={{field: 'id', order: 'desc'}}
            storeKey='selectMedia'
            disableSyncWithLocation>
            <Content {...props} />
        </ListBase>
    );
};
